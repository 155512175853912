import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13 5.7a5.2 5.2 0 1 1-7.3 7.4 5.2 5.2 0 0 1 7.4-7.4M15.8 15.8l-2.7-2.7"
      />
    </Svg>
  );
};

export default Icon;
