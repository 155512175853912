import { ChainId, Currency, Token } from '@pancakeswap/sdk'
import { Text, QuestionHelper, AutoColumn, CurrencyLogo } from '@pancakeswap/uikit'
import styled from 'styled-components'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { useTranslation } from '@pancakeswap/localization'

import { SUGGESTED_BASES } from 'config/constants/exchange'
import { AutoRow } from '../Layout/Row'
import { CommonBasesType } from './types'

const AutoColumnStyled = styled(AutoColumn)`
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.gray800};
  padding: 8px;
`

const Label = styled(Text)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray300};

  ${({ theme }) => theme.mediaQueries.bdMd} {
    font-size: 14px;
    line-height: 1.28;
    letter-spacing: 0.4px;
  }
`

const CurrencyText = styled(Text)`
  font-weight: 500;
  line-height: 1;
  color: ${({ theme }) => theme.colors.gray100};
`

const ButtonWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: 4px;
`

const BaseWrapper = styled.div<{ disable?: boolean }>`
  border: 1px solid ${({ theme, disable }) => (disable ? theme.colors.gray200 : theme.colors.gray700)};
  border-radius: 16px;
  display: flex;
  padding: 6px 12px 6px 6px;
  align-items: center;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background-color: ${({ theme, disable }) => !disable && theme.colors.gray700};
  }
  background-color: ${({ theme, disable }) => disable && theme.colors.gray400};
`

const RowWrapper = styled.div`
  white-space: nowrap;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
  commonBasesType,
}: {
  chainId?: ChainId
  commonBasesType
  selectedCurrency?: Currency | null
  onSelect: (currency: Currency) => void
}) {
  const native = useNativeCurrency()
  const { t } = useTranslation()
  const pinTokenDescText = commonBasesType === CommonBasesType.SWAP_LIMITORDER ? t('Common tokens') : t('Common bases')

  return (
    <AutoColumnStyled gap="sm">
      <AutoRow>
        <Label>{pinTokenDescText}</Label>
        {commonBasesType === CommonBasesType.LIQUIDITY && (
          <QuestionHelper text={t('These tokens are commonly paired with other tokens.')} ml="4px" />
        )}
      </AutoRow>
      <RowWrapper>
        <ButtonWrapper>
          <BaseWrapper
            onClick={() => {
              if (!selectedCurrency || !selectedCurrency.isNative) {
                onSelect(native)
              }
            }}
            disable={selectedCurrency?.isNative}
          >
            <CurrencyLogo size="20px" currency={native} style={{ marginRight: 4 }} />
            <CurrencyText>{native?.symbol}</CurrencyText>
          </BaseWrapper>
        </ButtonWrapper>
        {(chainId ? SUGGESTED_BASES[chainId] || [] : []).map((token: Token) => {
          const selected = selectedCurrency?.equals(token)
          return (
            <ButtonWrapper key={`buttonBase#${token.address}`}>
              <BaseWrapper onClick={() => !selected && onSelect(token)} disable={selected}>
                <CurrencyLogo size="20px" currency={token} style={{ marginRight: 4, borderRadius: '50%' }} />
                <CurrencyText>{token.symbol}</CurrencyText>
              </BaseWrapper>
            </ButtonWrapper>
          )
        })}
      </RowWrapper>
    </AutoColumnStyled>
  )
}
