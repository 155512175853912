import { useTranslation } from '@pancakeswap/localization'
import { Currency, CurrencyAmount } from '@pancakeswap/sdk'
import { Column, Text, CurrencyLogo } from '@pancakeswap/uikit'
import { formatAmount } from '@pancakeswap/utils/formatFractions'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { MutableRefObject, useCallback, useMemo } from 'react'
import { FixedSizeList } from 'react-window'
import styled from 'styled-components'
import { useAccount } from 'wagmi'
import { useIsUserAddedToken } from '../../hooks/Tokens'
import { useCombinedActiveList } from '../../state/lists/hooks'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import { isTokenOnList } from '../../utils'
import { RowBetween, RowFixed } from '../Layout/Row'
import CircleLoader from '../Loader/CircleLoader'

function currencyKey(currency: Currency): string {
  return currency?.isToken ? currency.address : currency?.isNative ? currency.symbol : ''
}

const FixedItemWrap = styled.div`
  padding-right: 12px;
  padding-top: 4px;
`

const FixedSizeListStyled = styled(FixedSizeList)`
  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.gray700};
    border-radius: 16px;

    &:hover {
      background: ${({ theme }) => theme.colors.gray400};
    }
  }
`

const StyledBalanceText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  max-width: 5rem;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 1.28;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.white500};
`

function Balance({ balance }: { balance: CurrencyAmount<Currency> }) {
  return (
    <StyledBalanceText title={balance.toExact()}>
      {balance?.toExact() === '0' ? '0.0' : formatAmount(balance, 4)}
    </StyledBalanceText>
  )
}

const MenuItem = styled(RowBetween)<{ disabled: boolean; selected: boolean }>`
  padding: 7px 12px 7px 7px;
  width: 100%;
  height: 48px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) minmax(0, 72px);
  grid-gap: 7px;
  border-radius: 6px;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  :hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.colors.gray700};
  }
  background: ${({ theme, disabled, selected }) => (disabled || selected ? theme.colors.gray400 : 'transparent')};
  opacity: ${({ disabled, selected }) => (disabled && !selected ? 0.5 : 1)};
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  border-width: ${({ disabled, selected }) => (disabled || selected ? '1px' : '0')};
`

function CurrencyRow({
  currency,
  onSelect,
  isSelected,
  otherSelected,
}: {
  currency: Currency
  onSelect: () => void
  isSelected: boolean
  otherSelected: boolean
}) {
  const { address: account } = useAccount()
  const { t } = useTranslation()
  const key = currencyKey(currency)
  const selectedTokenList = useCombinedActiveList()
  const isOnSelectedList = isTokenOnList(selectedTokenList, currency)
  const customAdded = useIsUserAddedToken(currency)

  const balance = useCurrencyBalance(account ?? undefined, currency)

  // only show add or remove buttons if not on selected list
  return (
    <MenuItem
      className={`token-item-${key}`}
      onClick={() => (isSelected ? null : onSelect())}
      disabled={isSelected}
      selected={otherSelected}
    >
      <CurrencyLogo currency={currency} size="32px" />

      <Column gap="2px">
        <Text medium lineHeight={1} letterSpacing="0.2px" color="gray100">
          {currency?.symbol}
        </Text>
        <Text color="gray200" fontSize="13px" lineHeight={1.23} ellipsis maxWidth="200px">
          {!isOnSelectedList && customAdded && `${t('Added by user')} •`} {currency?.name}
        </Text>
      </Column>
      <RowFixed style={{ justifySelf: 'flex-end' }}>
        {balance != null ? <Balance balance={balance} /> : account ? <CircleLoader /> : null}
      </RowFixed>
    </MenuItem>
  )
}

export default function CurrencyList({
  height,
  currencies,
  selectedCurrency,
  onCurrencySelect,
  otherCurrency,
  fixedListRef,
  showNative,
  breakIndex,
}: {
  height: number | string
  currencies: Currency[]
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  otherCurrency?: Currency | null
  fixedListRef?: MutableRefObject<FixedSizeList | undefined>
  showNative: boolean
  breakIndex: number | undefined
}) {
  const native = useNativeCurrency()

  const itemData: (Currency | undefined)[] = useMemo(() => {
    let formatted: (Currency | undefined)[] = showNative ? [native, ...currencies] : [...currencies]
    if (breakIndex !== undefined) {
      formatted = [...formatted.slice(0, breakIndex), ...formatted.slice(breakIndex, formatted.length)]
    }
    return formatted
  }, [breakIndex, currencies, showNative, native])

  const Row = useCallback(
    ({ data, index, style }) => {
      const currency: any = data[index]

      let isSelected = false
      let otherSelected = false
      isSelected = Boolean(selectedCurrency && currency && selectedCurrency.equals(currency))
      otherSelected = Boolean(otherCurrency && currency && otherCurrency.equals(currency))

      const handleSelect = () => onCurrencySelect(currency)

      return (
        !!currency && (
          <FixedItemWrap style={style}>
            <CurrencyRow
              currency={currency}
              isSelected={isSelected}
              onSelect={handleSelect}
              otherSelected={otherSelected}
            />
          </FixedItemWrap>
        )
      )
    },
    [selectedCurrency, otherCurrency, onCurrencySelect],
  )

  const itemKey = useCallback((index: number, data: any) => `${currencyKey(data[index])}-${index}`, [])

  return (
    <FixedSizeListStyled
      height={height}
      ref={fixedListRef as any}
      width="100%"
      itemData={itemData}
      itemCount={itemData.length}
      itemSize={56}
      itemKey={itemKey}
    >
      {Row}
    </FixedSizeListStyled>
  )
}
