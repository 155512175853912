import { AtomBox } from "@pancakeswap/ui";
import { inputContainerVariants } from "./SwapWidget.css";

import { NumericalInput, NumericalInputProps } from "./NumericalInput";
import { Text } from "../../components";

type ZapStyle = "noZap" | "zap";

interface CurrencyInputPanelProps extends Omit<NumericalInputProps, "onBlur"> {
  onInputBlur?: () => void;
  id: string;
  zapStyle?: ZapStyle;
  top?: React.ReactNode;
  bottom?: React.ReactNode;
  right?: React.ReactNode;
  showBridgeWarning?: boolean;
  isModal?: boolean;
}
export function CurrencyInputPanel({
  value,
  onUserInput,
  onInputBlur,
  zapStyle,
  top,
  bottom,
  right,
  id,
  disabled,
  error,
  loading,
  showBridgeWarning,
  isModal,
}: CurrencyInputPanelProps) {
  return (
    <AtomBox position="relative" id={id} display="grid" gap="8px">
      <AtomBox display="flex" alignItems="center" justifyContent="space-between" data-id="top-container">
        {top}
      </AtomBox>
      <AtomBox display="flex" flexDirection="column" flexWrap="nowrap" position="relative" zIndex="1">
        <AtomBox
          as="label"
          display="grid"
          gap="2px"
          px="16px"
          py="16px"
          className={inputContainerVariants({
            hasZapStyle: !!zapStyle,
            showBridgeWarning: !!showBridgeWarning,
            isModal: !!isModal,
            error: Boolean(error),
          })}
        >
          <AtomBox
            display="flex"
            flexDirection="row"
            flexWrap="nowrap"
            color="text"
            fontSize="12px"
            lineHeight="16px"
            gap="16px"
          >
            <NumericalInput
              error={Boolean(error)}
              disabled={disabled}
              loading={loading}
              className="token-amount-input"
              value={value}
              onBlur={onInputBlur}
              onUserInput={(val) => {
                onUserInput(val);
              }}
            />
            {right}
          </AtomBox>
          {bottom}
        </AtomBox>

        {error ? (
          <Text pb="8px" fontSize="12px" color="red">
            {error}
          </Text>
        ) : null}

        {disabled && <AtomBox role="presentation" position="absolute" inset="0px" opacity="0.6" />}
      </AtomBox>
    </AtomBox>
  );
}
