import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 16 16" {...props}>
      <path
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m5.3 9.3-2 2 2 2m7.4-2H3.3m7.4-4.6 2-2-2-2m-7.4 2h9.4"
      />
    </Svg>
  );
};

export default Icon;
