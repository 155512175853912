import styled from 'styled-components'
import { Input } from '@pancakeswap/uikit'
import Row from '../Layout/Row'

export const SearchWrap = styled(Row)`
  position: relative;
`

export const SearchIconWrap = styled.div`
  position: absolute;
  top: 9px;
  left: 12px;
  pointer-events: none;
`

export const SearchInput = styled(Input)`
  padding: 8px 12px 8px 36px;
`
